@media only screen and (max-width : 767px) {

    /* Default CSS */
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .default-btn {
        padding: 8px 25px;
    }
    .section-title {
        span {
            font-size: 12px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            padding-top: 10px;
        }
    }
    h3 {
        font-size: 20px;
    }
    /* Default CSS End */

    /* Top Header CSS */
    .header-left {
        text-align: center;

        .header-left-card {
            ul {
                li {
                    padding-left: 0;
                    margin: 0 7px;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &::before {
                        display: none;
                    }
                    &:first-child::before {
                        display: none;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    .head-icon {
                        left: 0;
                        top: 2px;
                        transform: translateY(-0%);
                        font-size: 20px;
                    }
                    a {
                        margin-top: 0;
                        font-size: 14px;
                    }
                }
            }
        } 
    }
    .header-right {
        float: none;
        max-width: 270px;
        margin: 0 auto;
        padding-left: 12px;
    }
    .top-social-link {
        float: none;
        margin: 0 auto;
        max-width: 270px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 15px;
    }
    /* Top Header CSS End */

    /* Navbar CSS */
    .mobile-nav {
        .logo {
            img {
                max-width: 120px;
                height: 40px;
                margin-top: -5px;
            }
        }
    }
    .others-options {
        display: none !important;
    }
    .side-nav-responsive {
        display: block;
    }
    .side-nav-responsive {
        .container {
            position: relative;
            display: flex;

            .container {
                position: absolute;
                top: 55px;
                right: -20px;
                max-width: 185px;
                margin-left: auto;
                opacity: 0;
                visibility: hidden;
                transition: 0.7s;
                transform: scaleX(0);
                z-index: 2;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .search-overlay.search-popup {
        width: 260px;
        margin-top: 15px;
    }
    .nav-btn {
        display: none;
    }
    /* Navbar CSS End */

    /* Main Banner CSS */
    .slider-area {
        .owl-nav {
            margin-top: 0;

            .owl-prev {
                top: auto;
                transform: translateY(0px);
                left: 35%;
                bottom: 80px;
            }
            .owl-next {
                top: auto;
                transform: translateY(0px);
                right: 35%;
                bottom: 80px;
            }
        }
    }
    .slider-item {
        &::before {
            width: 100%;
        }
    }
    
    .slider-content {
        padding-top: 130px;
        padding-bottom: 160px;
        text-align: center;

        h1 {
            margin-top: 10px;
            font-size: 34px;
            margin-bottom: 15px;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
        }
    }

    .banner-content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;

        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
        }
    }
    .banner-img {
        margin-top: 30px;
        margin-bottom: 70px;
        margin-right: 15px;

        img {
            border-radius: 0;
        }
    }
    /* Main Banner CSS End */

    /* Inner Banner */
    .inner-banner {
        padding: {
            top: 135px;
            bottom: 60px;
        };
        .inner-title {
            h3 {
                font-size: 26px;
            }
            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }
    /* Inner Banner End */

    /* Choose Area CSS */
    
    .choose-card {
        text-align: center;
        padding: 20px 10px;

        .choose-icon {
            position: inherit;
            top: 0;
            left: auto;
            right: auto;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
        .content {
            margin-left: auto;
            margin-right: auto;

            h3 {
                font-size: 22px;
            }
            p {
                margin-left: auto;
                margin-right: auto; 
                max-width: 260px;
            }
            .read-more-btn {
                padding: 7px 5px 7px 45px;
            }
        }
    }
    .choose-item {
        padding: 30px 20px;

        h3 {
            font-size: 21px;
        }
    }
    /* Choose Area CSS End */
    
    /* About Area CSS */
    .about-img {
        margin: 0 0 30px;
        position: relative;
    }
    .about-img-shape {
        display: none;
    }
    .about-content {
        margin-bottom: 30px;
        margin-left: 0;

        .section-title {
            margin-bottom: 20px;
        }
    }
    .about-img-2 {
        margin-bottom: 50px;
        margin-left: 20px;

        &::before {
            top: 20px;
            left: -20px;
        }
    }
    /* About Area CSS End */

    /* Service Area CSS */
    .service-item {
        h3 {
            font-size: 22px;
            margin-bottom: 10px;
        }
        p {
            max-width: 210px;
        }
    }
    /* Service Area CSS End */

    /* Pricing Area CSS */
    .pricing-area {
        .section-title {
            margin-bottom: 50px;
        }
    }
    .pricing-card {
        margin-bottom: 30px;

        .pricing-card-into {
            padding: 0 30px 20px;

            .pricing-icon {
                width: 65px;
                height: 65px;
                font-size: 24px;
                line-height: 65px;
            }
            h3 {
                font-size: 18px;
                padding: 7px;
            }
        }
        .price-rate {
            h2 {
                font-size: 35px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
        .purchase-btn {
            margin-top: 15px;
            padding: 7px 16px;
            font-size: 13px;
        }
    }
    /* Pricing Area CSS End */

    /* product Area CSS */
    .single-product {
        a {
            img {
                width: 100%;
            }
        }
        .product-content {
            padding: 30px 10px;

            ul {
                li {
                    padding: 0 3px;
                }
            }
            h3 {
                font-size: 18px;
            }
        }
    }

    .product-desc {
        h3 {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
        }
    }
    .products-tabs-reviews {
        ul {
            li {
                padding-left: 0;
                padding-bottom: 30px;

                img {
                    position: initial;
                    top: 0;
                    left: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
    /* product Area CSS End */

    /* Cart Wraps Area */
    .cart-wraps-area {
        .cart-table {
            text-align: center;

            table  {
                thead {
                    tr {
                        th {
                            padding: 0 40px 10px;
                            white-space: nowrap;
                            font-size: 16px;

                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                        td {
                            white-space: nowrap;

                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .cart-buttons {
            text-align: center;

            .text-right {
                text-align: center !important;
                margin-top: 0;
            }
            .default-btn {
                display: block;
                text-align: center;
                width: 100%;
            }
        }
        .cart-totals {
            padding: 20px;
            margin-top: 35px;
            
            h3 {
                margin-bottom: 18px;
                font-size: 20px;
            }
            ul {
                li {
                    font-size: 14px;
                }
            }
            .default-btn {
                display: block;
                text-align: center;
                width: 100%;
            }
        }
        .cart-wraps {
            .continue-shopping-box {
                margin-bottom: 20px;
            }
        }
        .cart-calc {
            padding: 20px !important;
            margin-top: 30px;

            .cart-wraps-form {
                h3 {
                    font-size: 18px;
                }
                .form-group {
                    select {
                        width: 100%;
                    }
                }
                .default-btn {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    /* Cart Wraps Area End */

    /* Checkout Area */
    .checkout-area {
        .checkout-user {
            margin-bottom: 30px;
            padding: 20px 10px;

            span {
                font-size: 16px;
            }
        }
    }
    .billing-details {
        padding: 30px;

        h3 {
            font-size: 20px;
        }
        .form-group {
            margin-bottom: 15px;
        }
    }
    .order-details {
        .order-table {
            padding:30px 15px;

            h3 {
                font-size: 20px;
            }
            table {
                thead {
                    tr {
                        th {
                           padding: 15px 10px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .payment-box {
            padding: 30px;
        }
    }
    /* Checkout Area End */
     
    /* Achievements Area */
    .achievements-card {
        i {
            font-size: 50px;
        }
        h3 {
            margin-top: 15px;
            font-size: 25px;
        }
        span {
            font-size: 15px;
        }
    }
    /* Achievements Area End */

    /* Project Area */
    .project-title {
        margin-bottom: 20px;
    }
    .project-text {
        margin-top: 0;
        margin-bottom: 5px;
    }
    .project-tab {
        .tabs {
            li {
                margin-bottom: 7px;
                margin-right: 10px;

                a {
                    font-size: 15px;
                }
            }
        }
    }
    .project-card {
        .project-content {
            bottom: -140px;

            .content {
                p {
                    max-width: 245px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    /* Project Area End */

    /* Testimonials Area */
    .testimonials-area {
        .owl-dots {
            left: 0 !important;
            right: 0 !important;
            text-align: center;
            margin-bottom: 40px;
            bottom: -20px;
        }
    }
    .testimonials-content {
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        p {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
    .testimonials-img {
        margin-right: auto;
        margin-left: 25px;
        margin-bottom: 70px;
        margin-top: 15px;
    }
    /* Testimonials Area End */

    /* Blog Area */
    .blog-article {
        .blog-status {
            .blog-comment {
                float: none;
                margin-top: 15px;

                h3 {
                    font-size: 14px;
                }
            }
        }
        .article-content {
            h2 {
                font-size: 24px;
            }
            .blockquote {
                p {
                    font-size: 17px;
                    margin-left: 0;
                }
            }
        }
        .another-content {
            .content-img {
                .row {
                    .col-6 {
                        padding-left: 7px !important;
                        padding-right: 7px !important;
                    }
                }
            }
        }
        .blog-article-share {
            .social-icon {
                li {
                    margin-right: 0px;

                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }
        .blog-profile  {
            margin-top: 35px;
            padding-top: 0;

            ul {
                li {
                    padding-left: 0;

                    img {
                        position: inherit;
                        left: 0;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .comments-wrap {
            ul {
                li {
                    padding-left: 30px;

                    img {
                        position: inherit;
                        left: 0;
                        top: 0;
                        margin-bottom: 15px;
                    }
                    a {
                        position: inherit;
                        top: 0;
                        left: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
        .comments-form {
            margin-bottom: 40px;
        }
    } 

    .side-bar-widget {
        .widget-popular-post {
            .item {
                .info {
                    .title-text {
                        font-size: 17px
                    }
                }
            }
        }
    }
    /* Blog Area End */

    .pagination-area {
        margin-top: 0;
    }
    
    .user-all-form {
        .contact-form {
            .forget {
                float: none;
            }
        }
    }

    .appointment-form {
        margin-right: auto;
        margin-left: auto;
    }

    /* Error Area */
    .error-area {
        .error-content {
            h1 {
                font-size: 100px;
            }
            h3 {
                margin-top: 20px;
                font-size: 26px;
            }
            p {
                font-size: 15px;
                margin-top: 10px;
                padding: 0 15px;
            }
        }
    }
    /* Error Area End */

    /* Coming Soon */
    .coming-soon-area {
        .coming-soon-content {
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;

            h1 {
                font-size: 30px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 10px;

                div {
                    font-size: 30px;
                    margin-left: 5px;
                    margin-right: 5px;

                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 20px;
                margin-bottom: 20px;

                .input-newsletter {
                    height: 55px;
                    padding-left: 13px;
                    font-size: 15px;
                }
                button {
                    position: relative;
                    font-size: 14px;
                    margin-top: 15px;
                    width: 100%;
                    height: 50px;
                }
            }
        }
    }
    /* Coming Soon End */

    .footer-widget {
        .footer-logo {
            margin-bottom: 15px;
        }
        h3 {
            margin-top: 0;
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
    .footer-widget.pl-2, .footer-widget.px-2 {
        padding-left: 0 !important;
    }

}

@media only screen and (min-width : 576px) and (max-width : 767px) {

    .slider-area {
        .owl-nav {
            .owl-prev {
                left: 40%;
            }
            .owl-next {
                right: 40%;
            }
        }
    }

    .service-item {
        p {
            max-width: 310px;
        }
    }
     
    .project-card {
        .project-content {
            .content {
                p {
                    max-width: 320px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .cart-wraps-area {
        .cart-wraps {
            .continue-shopping-box {
                margin-bottom: 0;
            }
        }
    }

    .blog-article {
        .blog-status {
            .blog-comment {
                float: right;
                margin-top: 0;
                h3 {
                    font-size: 14px;
                }
            }
        }
    }

    .user-all-form {
        .contact-form {
            .forget {
                float: right;
            }
        }
    }

}

@media only screen and (min-width : 768px) and (max-width : 991px) {

    /* Default CSS */
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .default-btn {
        padding: 8px 25px;
    }
    .section-title {
        span {
            font-size: 12px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            padding-top: 10px;
        }
    }
    h3 {
        font-size: 20px;
    }
    /* Default CSS End */

    /* Top Header CSS */
    .header-left {
        .header-left-card {
            ul {
                li {
                    margin-right: 15px;

                    &::before {
                        height: 20px;
                        left: -8px;
                    }
                }
            }
        }
    }
    .top-social-link {
        ul {
            li {
                display: inline-block;
                margin-right: 3px;
            }
        }
    }
    /* Top Header CSS End */

    /* Navbar CSS */
    .mobile-nav {
        .logo {
            img {
                max-width: 120px;
                height: 40px;
                margin-top: -5px;
            }
        }
    }
    .others-options {
        display: none !important;
    }
    .side-nav-responsive {
        display: block;
    }
    .side-nav-responsive {
        .container {
            position: relative;
            display: flex;

            .container {
                position: absolute;
                top: 55px;
                right: -20px;
                max-width: 185px;
                margin-left: auto;
                opacity: 0;
                visibility: hidden;
                transition: 0.7s;
                transform: scaleX(0);
                z-index: 2;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .search-overlay.search-popup {
        width: 260px;
        margin-top: 15px;
    }
    .nav-btn {
        display: none;
    }
    /* Navbar CSS End */

    /* Main Banner CSS */
    .slider-area {
        .owl-nav {
            margin-top: 0;

            .owl-prev {
                top: auto;
                transform: translateY(0px);
                left: 43%;
                bottom: 80px;
            }
            .owl-next {
                top: auto;
                transform: translateY(0px);
                right: 43%;
                bottom: 80px;
            }
        }
    }
    .slider-content {
        padding-top: 130px;
        padding-bottom: 160px;

        h1 {
            margin-top: 10px;
            font-size: 34px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
        }
    }

    .banner-content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;

        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
        }
    }
    .banner-img {
        margin-top: 30px;
        margin-bottom: 70px;
        margin-right: 15px;

        img {
            border-radius: 0;
        }
    }
    /* Main Banner CSS End */

    /* Inner Banner */
    .inner-banner {
        padding: {
            top: 120px;
            bottom: 60px;
        };
        .inner-title {
            h3 {
                font-size: 26px;
            }
            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }
    /* Inner Banner End */

    /* Choose Area CSS */
    .choose-card {
        text-align: center;
        padding: 30px 15px;

        .choose-icon {
            position: inherit;
            top: 0;
            left: auto;
            right: auto;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
        .content {
            margin-left: auto;
            margin-right: auto;

            h3 {
                font-size: 22px;
            }
            p {
                margin-left: auto;
                margin-right: auto; 
                max-width: 260px;
            }
            .read-more-btn {
                padding: 7px 5px 7px 45px;
            }
        }
    }
    /* Choose Area CSS End */
    
    /* About Area CSS */
    .about-img {
        margin: 0 0 30px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .about-img-shape {
        display: none;
    }
    .about-content {
        margin-bottom: 30px;
        margin-left: 0;

        .section-title {
            margin-bottom: 20px;
        }
    }
    /* About Area CSS End */

    /* Service Area CSS */
    .service-item {
        h3 {
            font-size: 22px;
            margin-bottom: 10px;
        }
    }
    /* Service Area CSS End */

    /* Pricing Area CSS */
    .pricing-area {
        .section-title {
            margin-bottom: 50px;
        }
    }
    .pricing-card {
        margin-bottom: 30px;

        .pricing-card-into {
            padding: 0 30px 20px;

            .pricing-icon {
                width: 65px;
                height: 65px;
                font-size: 24px;
                line-height: 65px;
            }
            h3 {
                font-size: 18px;
                padding: 7px;
            }
        }
        .price-rate {
            h2 {
                font-size: 35px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
        .purchase-btn {
            margin-top: 15px;
            padding: 7px 16px;
            font-size: 13px;
        }
    }
    /* Pricing Area CSS End */

    /* Achievements Area */
    .achievements-card {
        i {
            font-size: 50px;
        }
        h3 {
            margin-top: 15px;
            font-size: 25px;
        }
        span {
            font-size: 15px;
        }
    }
    /* Achievements Area End */

    /* Project Area */
    .project-title {
        margin-bottom: 20px;
    }
    .project-text {
        margin-top: 0;
        margin-bottom: 5px;
    }
    .project-tab {
        .tabs {
            li {
                margin-bottom: 7px;
                margin-right: 10px;

                a {
                    font-size: 15px;
                }
            }
        }
    }
    .project-card {
        .project-content {
            bottom: -140px;

            .content {
                p {
                    max-width: 245px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    /* Project Area End */

    /* Testimonials Area */
    .testimonials-area {
        .owl-dots {
            left: 0 !important;
            right: 0 !important;
            text-align: center;
            margin-bottom: 40px;
            bottom: -20px;
        }
    }
    .testimonials-content {
        margin-left: auto;
        margin-right: auto;

        p {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
    .testimonials-img {
        margin-right: auto;
        margin-left: 25px;
        margin-bottom: 70px;
        margin-top: 15px;

        .quote-text {
            top: -15px;
        }
    }
    /* Testimonials Area End */

    .blog-card {
        .content {
            h3 {
                font-size: 21px;
            }
        }
    }

    .blog-details-area {
        .blog-card {
            .content {
                h3 {
                    font-size: 19px;
                }
            }
        }
    }

    .appointment-form {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    /* Error Area */
    .error-area {
        .error-content {
            h1 {
                font-size: 120px;
            }
            h3 {
                margin-top: 20px;
                font-size: 30px;
            }
            p {
                font-size: 15px;
                margin-top: 10px;
                padding: 0 15px;
            }
        }
    }
    /* Error Area End */

    /* Coming Soon */
    .coming-soon-area {
        .coming-soon-content {
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;

            h1 {
                font-size: 40px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 10px;

                div {
                    font-size: 35px;
                    margin-left: 15px;
                    margin-right: 15px;

                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 20px;
            }
        }
    }
    /* Coming Soon End */

    .footer-widget {
        .footer-logo {
            margin-bottom: 15px;
        }
        h3 {
            margin-top: 0;
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
    .footer-widget.pl-2, .footer-widget.px-2 {
        padding-left: 0 !important;
    }

}

@media only screen and (min-width : 992px) and (max-width : 1199px) {

    .slider-area {
        .owl-nav {
            margin-top: 0;

            .owl-prev {
                left: 0;
            }
            .owl-next {
                right: 0;
            }
        }
    }
    .slider-content {
        margin-left: 30px;

        h1 {
            font-size: 55px;
        }
    }
    .banner-content {
        max-width: 445px;

        h1 {
            font-size: 50px;
        }
    }
     
    .pricing-card {
        .pricing-card-into {
            padding: 0 20px 30px;
        }
    }
    
    .achievements-card {
        padding: 45px 35px;
        
        i {
            font-size: 50px;
        }
        h3 {
            margin-top: 15px;
            font-size: 25px;
        }
        span {
            font-size: 15px;
        }
    }

    .testimonials-area {
        .owl-dots {
            bottom: 40px;
        }
    }

    .side-bar-widget {
        .widget-popular-post {
            .item {
                .info {
                    .title-text {
                        font-size: 17px
                    }
                }
            }
        }
    }

    .footer-widget {
        h3 {
            font-size: 22px;
        }
        .footer-list-two {
            li {
                font-size: 15px;
            }
        }
    }
    .footer-widget.pl-2, .footer-widget.px-2 {
        padding-left: 0 !important;
    }

}